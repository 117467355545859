import { post, get, postByQuery } from '@/api/request'

//登录
export const requestLogin = params => {
  return post('/user/loginAdmin', params)
}

//获取个人信息
export const getSysUser = params => {
  return get('/user/getSysUser', params)
}

//用户列表（分页）
export const userQueryList = params => {
  return post('/auth/userQueryList', params)
}

//用户列表（不分页）
export const userQueryPage = params => {
  return post('/auth/userQueryPage', params)
}

//通过ID获取单条数据
export const userSelectOne = params => {
  return get('/auth/userSelectOne', params)
}

//用户添加
export const userAdd = params => {
  return post('/auth/userAdd', params)
}

//用户修改
export const userEdit = params => {
  return post('/auth/userEdit', params)
}

//用户删除
export const userDelete = params => {
  return postByQuery('/auth/userDelete', params)
}

//修改密码
export const userEditPwd = params => {
  return postByQuery('/auth/userEditPwd', params)
}

//修改密码
export const editPassword = params => {
  return get('/auth/editPassword', params)
}