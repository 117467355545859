<template>
  <div v-loading="isLoading" style="margin-top: 10px">
    <div>
      <el-table
        :data="tableData"
        stripe
        :height="`calc(100vh - 260px)`"
        highlight-current-row
        :header-cell-style="{
          background: '#FFF',
          color: '#212121',
          'text-algin': 'left',
        }"
        :cell-style="{ 'text-algin': 'left' }"
        :tree-props="treeProps"
        :row-key="rowKey"
        tooltip-effect="light"
        @selection-change="handleSelectionChange"
        @sort-change="handleSortChange"
        @select="checkedOne"
        @select-all="handleAllChange"
        ref="mpTable"
      >
        <slot></slot>
        <template slot="empty">
          <span>当前无数据</span>
        </template>
      </el-table>
      <div class="pagination" v-if="totalNum != 0">
        <el-pagination
          background
          layout="prev, pager, next,total"
          :total="parseInt(totalNum)"
          :current-page="pageNumber"
          @current-change="handleCurrentChange"
          @size-change="handleSizeChange"
          :page-size="pageSizeNumber"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pageSizeNumber: 10,
      pageNumber: 1,
    };
  },
  props: {
    //表格显示数据
    tableData: {
      type: Array,
      default() {
        return [];
      },
    },
    //数据总共数量，用于分页
    totalNum: {
      default: 0,
    },
    //是否是加载状态
    isLoading: {
      type: Boolean,
      default: false,
    },
    //当前页
    pageNum: {
      type: Number,
      default: 1,
    },
    //每页数据量
    pageSize: {
      type: Number,
      default: 10,
    },
    //默认勾选的数据
    selectedList: {
      type: Array,
      default() {
        return [];
      },
    },
    treeProps: {
      type: Object,
      default() {
        return { hasChildren: "hasChildren", children: "children" };
      },
    },
    rowKey: {
      type: String,
      default: "",
    },
  },
  watch: {
    pageNum: {
      handler(val) {
        this.pageNumber = val;
      },
      deep: true,
      immediate: true,
    },
    pageSize: {
      handler(val) {
        this.pageSizeNumber = val;
      },
      deep: true,
      immediate: true,
    },
    selectedList: {
      handler(val) {
        if (val.length > 0) {
          val.forEach((item) => {
            this.$nextTick(() => {
              this.$refs.mpTable.toggleRowSelection(item);
            });
          });
        }
      },
      deep: true,
    },
  },
  updated() {
    this.$nextTick(() => {
      this.$refs.mpTable.doLayout();
    });
  },
  methods: {
    //多选框改变时触发
    handleSelectionChange(val) {
      this.$emit("selection-change", val);
    },
    checkedOne(val, row) {
      this.$emit("select", val, row);
    },
    handleAllChange(val) {
      this.$emit("select-all", val);
    },
    //更改页数时触发
    handleCurrentChange(e) {
      this.pageNumber = e;
      this.$emit("getData", {
        pageNum: this.pageNumber,
        pageSize: this.pageSizeNumber,
      });
    },
    //每页条数改变时触发
    handleSizeChange(e) {
      this.pageSizeNumber = e;
      this.pageNumber = 1;
      this.$emit("getData", {
        pageNum: this.pageNumber,
        pageSize: this.pageSizeNumber,
      });
    },
    //排序改变时触发
    handleSortChange(column) {
      this.$emit("sort-change", column);
    },
    //表格初始化方法
    initData() {
      this.pageNumber = 1;
      this.$emit("getData", {
        pageNum: this.pageNumber,
        pageSize: this.pageSizeNumber,
      });
    },
  },
};
</script>

<style scoped>
</style>