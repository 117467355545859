import { requestLogin, getSysUser } from "@/api/system/user";
import { setToken } from '@/utils/auth'

const user = {
    state: {
        nickName: '',
        permissions: [],
        menuActive: ''

    },
    mutations: {
        setUserInfo(state, e) {
            state.nickName = e.nickName
        },
        setUserInfo1() {
        },
        setMenuActive(state, e) {
            state.menuActive = e
        }
    },
    actions: {
        login({ commit }, e) {
            return new Promise((resolve, reject) => {
                requestLogin(e).then(res => {
                    if (res.code == 0) {
                        commit('setUserInfo1')
                        setToken(res.result.token)
                    }
                    resolve(res)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        getUserInfo({ commit }) {
            return new Promise((resolve, reject) => {
                getSysUser({}).then(res => {
                    if (res.code == 0) {
                        commit('setUserInfo', res.result)
                        resolve(res.result.sysRole.sysMenuList)
                    } else {
                        reject()
                    }
                }).catch(error => {
                    reject(error)
                })
            })
        },
        setMenuActive({ commit }, e) {
            commit('setMenuActive', e)
        }
    }
}

export default user