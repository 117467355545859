import axios from 'axios'
import router from '../router/index'
import { Message } from 'element-ui'
import { getToken, removeToken } from '@/utils/auth'

const service = axios.create({
  timeout: 120000,
  withCredentials: false,
  baseURL: process.env.VUE_APP_BASE_API
})


export function get(url, params) {
  return new Promise((resolve, reject) => {
    service.get(url, {
      params: params,
    }).then(res => {
      resolve(res.data)
    }).catch(err => {
      reject(err.data)
    })
  })
}

export function postByForm(url, data) {
  return new Promise((resolve, reject) => {
    service({
      url,
      method: 'POST',
      data,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    }).then(res => {
      resolve(res.data)
    }).catch(err => {
      reject(err.data)
    })
  })
}

export function post(url, params) {
  return new Promise((resolve, reject) => {
    service.post(url, params).then(res => {
      resolve(res.data)
    }).catch(err => {
      reject(err.data)
    })
  })
}

export function deleteType(url, params) {
  return new Promise((resolve, reject) => {
    service.delete(url, params).then(res => {
      resolve(res.data)
    }).catch(err => {
      reject(err.data)
    })
  })
}

export function postByQuery(url, params) {
  return new Promise((resolve, reject) => {
    service({
      url,
      method: 'post',
      params
    }).then(res => {
      resolve(res.data)
    }).catch(err => {
      reject(err.data)
    })
  })
}

service.interceptors.request.use(config => {
  if (`/user/loginAdmin` == config.url) {
    return config
  } else {
    const token = getToken('rvToken')
    config.headers['Authorization'] = 'Bearer ' + token
    if (token) {
      return config
    } else {
      Message.error({
        //message: 'token错误，请登录后重试',
        message: '用户信息过期，请重新登录',
      })
      setTimeout(function () {
        router.push({ name: 'Login' })
      }, 1000)
    }
  }
  return config
}, error => {
  Message.error({
    message: '加载超时',
  })
  return Promise.reject(error)
})

/**/

service.interceptors.response.use(response => {
  const res = response.data;
  if (res.code != 0) {
    if (401 == res.code || 402 == res.code || 403 ==
      res.code) {
      Message.error({
        //message: 'token异常，登录后重试',
        message: '用户信息无效，请重试',
      })
      removeToken()
      router.push('/login')
    } else if (404 == res.code) {
      router.push('/404')
    } else if (1 == res.code) {
      Message.error({
        message: res.msg,
      })
    } else if (500 == res.code || 500 == res.status) {
      Message.error({
        message: '系统错误',
      })
    } else {
      Message.error({
        message: res.msg,
      })
    }
    return Promise.reject(new Error('Error'));
  } else {
    return response
  }
}, error => {
  Message.error({
    message: '网络错误',
  })
  return Promise.reject(error)
},
)

